import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import H1 from "../typography/H1"
import SvgPlay from "./svgs/SvgPlay"
import Container from "../layouts/container/Container"
import ColumnsTwo from "../layouts/container/ColumnsTwo"
import P from "../typography/P"

const Header = ({
  image,
  subtitle,
  title,
  to,
  text,
  buttonText,
  meta,
  color,
}) => {
  return (
    <div className="pb-4 mmd:py-16 bg-black">
      <Container>
        <ColumnsTwo>
          <div className="overflow-hidden mb-6 rounded-xl shadow-xl mmd:mb-0">
            <Link to={to}>
              <Img
                fluid={image}
                className="transform hover:scale-110 duration-300 "
              />
            </Link>
          </div>
          <div className="flex items-center text-white">
            <div>
              <span>{subtitle}</span>
              <H1 className="mb-4">
                <Link
                  to={to}
                  className={`${
                    color !== undefined
                      ? `hover:shadow-title-${color} transition-shadow duration-300`
                      : ""
                  }`}
                >
                  {title}
                </Link>
              </H1>
              <div className="text-xs mb-3">{meta}</div>

              <P textColor="text-gray-300" className="mb-3">
                {text}
              </P>
              <Link
                to={to}
                className="inline-flex py-1 pl-1 pr-2 -ml-1 rounded-lg items-center flex-row hover:bg-black900"
              >
                <SvgPlay className="h-16 w-16 mr-3" /> {buttonText}
              </Link>
            </div>
          </div>
        </ColumnsTwo>
      </Container>
    </div>
  )
}

export default Header
