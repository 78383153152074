import React from "react"
import { graphql } from "gatsby"
import Header from "../components/commons/Header"
import Layout from "./../components/layouts/Default"
import Container from "../components/layouts/container/Container"
import CardEpisode from "../components/commons/CardEpisode"
import ListEpisodeIndex from "../components/commons/ListEpisodeIndex"
import ColumnsTwo from "../components/layouts/container/ColumnsTwo"
import SectionTitle from "../components/commons/SectionTitle"
import NewsletterSection from "../components/commons/NewsletterSection"
import { convertDate } from "../components/functions/convertDate"

export default function Page({ data, location }) {
  let headerColor
  data.allCockpitThemen.nodes.forEach(element => {
    if (element.thema.value === data.header.nodes[0].thema.value.display) {
      headerColor = element.farbe.value
    }
  })

  return (
    <Layout
      location={location}
      title="Die Summe aller Dinge Podcast"
      desc="Die Summe aller Dinge ist dein wöchentlicher Arschtritt für mehr Lebensqualität."
    >
      <Header
        image={data.header.nodes[0].titelbild.value.childImageSharp.fluid}
        subtitle="Neuste Folge"
        title={data.header.nodes[0].titel.value}
        to={`/folgen/${data.header.nodes[0].folgennummer.value}/${data.header.nodes[0].slug.slug}/`}
        meta={`Folge ${data.header.nodes[0].folgennummer.value} - ${convertDate(
          data.header.nodes[0].datum.value
        )} - Thema: ${data.header.nodes[0].thema.value.display}`}
        text={data.header.nodes[0].einleitung.value}
        buttonText="Folge abspielen"
        color={headerColor}
      />

      <Container>
        <ColumnsTwo className="my-16">
          {data.allCockpitThemen.nodes.map((topic, i) => {
            let matchinEpisodes = []
            data.listAll.nodes.forEach(episode => {
              if (topic.thema.value === episode.thema.value.display) {
                matchinEpisodes.push(episode)
              }
            })
            if (matchinEpisodes[0] !== undefined) {
              return (
                <section>
                  <SectionTitle
                    title={`Folgen zu ${topic.thema.value}`}
                    linktext="Alle Folgen"
                    to={`/folgen/${topic.slug.slug}`}
                    color={topic.farbe.value}
                    className="mb-6"
                  />
                  <CardEpisode
                    title={matchinEpisodes[0].titel.value}
                    image={
                      matchinEpisodes[0].titelbild.value.childImageSharp.fluid
                    }
                    meta={`Folge ${
                      matchinEpisodes[0].folgennummer.value
                    } - ${convertDate(matchinEpisodes[0].datum.value)}`}
                    text={matchinEpisodes[0].einleitung.value}
                    to={`/folgen/${matchinEpisodes[0].folgennummer.value}/${matchinEpisodes[0].slug.slug}/`}
                    color={topic.farbe.value}
                    className="mb-6"
                  />
                  <ListEpisodeIndex listArray={matchinEpisodes.slice(1, 4)} />
                </section>
              )
            }

            return <></>
          })}
        </ColumnsTwo>
      </Container>
      <NewsletterSection />
    </Layout>
  )
}

export const query = graphql`
  query {
    header: allCockpitFolgen(
      sort: { fields: folgennummer___value, order: DESC }
      limit: 1
      filter: { public: { value: { eq: true } } }
    ) {
      nodes {
        titel {
          value
        }
        slug {
          slug
        }
        thema {
          value {
            display
          }
        }
        titelbild {
          value {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        einleitung {
          value
        }
        folgennummer {
          value
        }
        datum {
          value
        }
      }
    }

    allCockpitThemen {
      nodes {
        farbe {
          value
        }
        thema {
          value
        }
        slug {
          slug
        }
      }
    }

    listAll: allCockpitFolgen(
      sort: { fields: folgennummer___value, order: DESC }
      skip: 1
      filter: { public: { value: { eq: true } } }
    ) {
      nodes {
        titel {
          value
        }
        slug {
          slug
        }
        thema {
          value {
            display
          }
        }
        titelbild {
          value {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        einleitung {
          value
        }
        folgennummer {
          value
        }
        datum {
          value
        }
      }
    }
  }
`
