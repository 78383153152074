import React from "react"
import { Link, navigate } from "gatsby"
import Container from "../layouts/container/Container"
import H2 from "../typography/H2"
import Button from "../commons/Button"
import P from "../typography/P"

const NewsletterSection = () => {
  const handleResult = response => {
    if (response === "OK") {
      navigate("/newsletter-gesendet/")
    } else {
      navigate("/newsletter-gesendet/")
    }
  }

  const handleSubscription = e => {
    e.preventDefault()
    const { target: form } = e

    var raw = JSON.stringify({ email: form.email.value })

    var requestOptions = {
      method: "POST",
      body: raw,
    }

    fetch("/.netlify/functions/sipRegistration", requestOptions)
      .then(function (response) {
        return response.text()
      })
      .then(function (data) {
        handleResult(data) // this will be a string
      })
      .catch(error => console.log("error", error))
  }

  return (
    <div className="my-16">
      <Container>
        <div className="px-6 py-6 bg-gray-100 rounded-lg mmd:grid mmd:grid-cols-8 shadow-xl">
          <div className="mmd:col-span-3 mb-3 mdd:mb-0">
            <H2 className="mb-3">Newsletter</H2>
            <p>
              Verpasse keine Folge mehr und erhalte vorab Einblicke in unseren
              Podcast.
            </p>
          </div>
          <div className="mmd:col-span-5 mmd:pl-3 self-center">
            <form
              className="mmd:flex items-strech mb-1"
              onSubmit={handleSubscription}
            >
              <label htmlFor="emailAddress" className="sr-only">
                Deine E-Mailadresse
              </label>
              <div className="mmd:pr-3 mmd:flex-1 flex items-stretch mb-3 mmd:mb-0">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="w-full border-white px-5 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black700 focus:ring-white rounded-md"
                  placeholder="Deine E-Mailadresse"
                />
              </div>
              <Button
                text="Zum Newsletter anmelden"
                className="flex-1"
                submit
              />
            </form>
            <P className="text-xs">
              Wir respektieren Dein Vertrauen und behandeln Deine Daten mit
              Respekt. Mit dem Absenden dieses Formulars akzeptierst Du unsere{" "}
              <Link to="/datenschutz/" className="font-medium underline">
                Datenschutzerklärung
              </Link>
              .
            </P>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default NewsletterSection
