import React from "react"

const ColumnsTwo = ({ children, className }) => {
  return (
    <div
      className={`grid grid-cols-1 mmd:grid-cols-2 gap-8 ${
        className ? className : ""
      }`}
    >
      {children}
    </div>
  )
}

export default ColumnsTwo
