import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const ListEpisodeIndex = ({ listArray, className }) => {
  const data = useStaticQuery(graphql`
    query {
      allCockpitThemen {
        nodes {
          thema {
            value
          }
          farbe {
            value
          }
        }
      }
    }
  `)

  return (
    <div className={className ? className : ""}>
      {listArray.map((item, i) => {
        let color
        data.allCockpitThemen.nodes.forEach(topic => {
          if (topic.thema.value === item.thema.value.display) {
            color = topic.farbe.value
          }
        })

        return (
          <div
            className={`grid grid-cols-8 ${
              listArray.length === i + 1 ? "" : "mb-8"
            }`}
            key={i}
          >
            <div className={`col-span-1`}>
              <Link
                to={`/folgen/${item.folgennummer.value}/${item.slug.slug}/`}
              >
                <Img
                  fluid={item.titelbild.value.childImageSharp.fluid}
                  className="transform hover:scale-105 duration-300 rounded shadow w-full"
                  style={{ height: "inherit" }}
                />
              </Link>
            </div>
            <div className={`col-span-7 pl-3 flex justify-center flex-col`}>
              <h3 className={`font-bold `}>
                <Link
                  to={`/folgen/${item.folgennummer.value}/${item.slug.slug}/`}
                  className={`${
                    color !== undefined
                      ? `hover:shadow-text-${color} transition-shadow duration-300`
                      : ""
                  }`}
                >
                  {item.titel.value}
                </Link>
              </h3>

              <span className="text-xs text-gray-500">
                Folge: {item.folgennummer.value}
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ListEpisodeIndex
