import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import H3 from "../typography/H3"
import SvgPlay from "./svgs/SvgPlay"
import P from "../typography/P"

const CardEpisode = ({ image, to, title, meta, text, className, color }) => {
  return (
    <div className={className ? className : ""}>
      <div className="overflow-hidden mb-6 rounded-xl shadow-xl">
        <Link to={to}>
          <div className="relative">
            <Img
              fluid={image}
              className="transform hover:scale-110 duration-300"
            />
            <SvgPlay className="h-16 w-16 transform hover:scale-110 duration-300 absolute left-4 bottom-4 " />
          </div>
        </Link>
      </div>
      <div className="flex items-center">
        <div>
          <H3 className="mb-4">
            <Link
              to={to}
              className={`${
                color !== undefined
                  ? `hover:shadow-title-${color} transition-shadow duration-300`
                  : ""
              }`}
            >
              {title}
            </Link>
          </H3>
          <div className="text-xs mb-3">{meta}</div>

          <P className="mb-3">{text}</P>
        </div>
      </div>
    </div>
  )
}

export default CardEpisode
